import { Button, Layout, Title } from "@components";
import { LocaleContext } from "@context";
import React, { useContext } from "react";

interface Props {
    location: unknown;
}

const ErrorPage = ({ location }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    return (
        <Layout lang={locale} title={`404 Page not found`} location={location}>
            <main className="container-lg text-center">
                <Title tag="h1">ERROR 404: PAGE NOT FOUND</Title>
                <p>Whoops, something went wrong :(</p>
                <Button link="/" label="Back to homepage" />
            </main>
        </Layout>
    );
};
export default ErrorPage;
